$primary: red;
$secondary: blue;

:export {
  primary: $primary;
  secondary: $secondary;
}

// Default theme colors.
$defaultWhite: #ffffff;
$defaultBg: #f2f3ee;
$defaultLight: #e4eacc;
$defaultMedium: #CFDBCF;
$defaultCopy: #495a49;
$defaultCopyDarkened: darken($defaultCopy, 20%);
$defaultShadow: #afb0aa;
$defaultFormErr: #B31717;
$defaultFormErrBg: #F4E7E7;
$defaultAlert: #E06261;
$defaultSeparatorColor: #CCD6CC;

// Fonts
$sans-serif: 'Roboto', sans-serif;
$condensed: 'Roboto Condensed', sans-serif;

$stacking-order: (
  'modal': 9000,
);

// transitions
$standardTrans: all 0.3s ease;
