
@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/variables.scss' as v;

@mixin detailSection {
  border-radius: 5px;
  overflow: hidden;
}

.detailSection {
  @include detailSection;

  & + .detailSection  {
    margin-top: 25px;

    @include m.tablet {
      margin-top: 40px;
    }

    @include m.smallDesktop {
      margin-top: 50px;
    }
  }

  &--gallery {
    border-radius: 0;
    overflow: visible;

    .slideshowContainer {
      @include detailSection;
    }
  }
}

#view-scroll .slideshowContainer {
  @include detailSection;
}

.detailNav + .detailSection {

  @include m.tablet {
    margin-top: 40px;
  }

  @include m.smallDesktop {
    margin-top: 50px;
  }
}
