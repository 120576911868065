@use '../../../GlobalScss/mixins/mixins.scss' as m;
@use '../../../GlobalScss/variables.scss' as v;

.slideshow {
  display: flex;
  width: 100%;
  margin-top: 40px;
  position: relative;
  border-radius: 5px;

  .slideshowControls {
    position: absolute;
    z-index: 999;
    display: block;
    position: absolute;
    background-color: v.$defaultWhite;
    top: 50%;
    overflow: visible;
    width: 23px;
    height: 45px;
    margin-top: -(calc(45px/2));

    @include m.tablet {
      width: 46px;
      height: 90px;
      margin-top: -40px;
    }

    &.slideshowControls-left {
      left: 0;
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;

      @include m.tablet {
        border-top-right-radius: 46px;
        border-bottom-right-radius: 46px;
      }

      &:hover .arrow {
        left: -10px;

        @include m.tablet {
          left: -20px;
        }
      }

      .arrow {
        transform: rotate(90deg);
        left: -5px;

        @include m.tablet {
          left: -10px;
        }
      }
    }

    &.slideshowControls-right {
      right: 0;
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;

      @include m.tablet {
        border-top-left-radius: 46px;
        border-bottom-left-radius: 46px;
      }

      &:hover .arrow {
        right: -10px;

        @include m.tablet {
          right: -20px;
        }
      }

      .arrow {
        right: -5px;
        transform: rotate(-90deg);

        @include m.tablet {
          right: -10px;
        }
      }
    }

    .arrow {
      width: 24px;
      display: block;
      position: absolute;
      top: 50%;
      transition: all 0.3s ease;

      @include m.tablet {
        width: 48px;
      }

      span {
        position: absolute;
        top: 50%;
        background-color: v.$defaultCopy; // Default theme
        display: inline-block;
        transform-origin: center center;
        width: 12px;
        height: 1.5px;
        border-radius: 1.5px;

        @include m.tablet {
          width: 29px;
          height: 3px;
          border-radius: 3px;
          top: 50%;
        }

        &:first-of-type {
          left: 2px;
          transform: rotate(45deg);
          @include m.tablet {
            left: 0;
          }
        }

        &:last-of-type {
          right: 2px;
          transform: rotate(-45deg);

          @include m.tablet {
            right: 0;
          }
        }
      }
    }
  }

  .slideshowContainer {
    position: relative;
    padding-bottom: 57%;
    flex: 1;
    display: flex;
    overflow: hidden;

    .slideshowBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      filter: blur(5px) opacity(50%);
    }

    .slideshowPhoto {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
