@use '../../GlobalScss/variables.scss' as v;
@use '../../GlobalScss/mixins/_type-mixins.scss' as tm;
@use '../../GlobalScss/mixins/mixins.scss' as m;

.detailDialog {
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  background: v.$defaultCopy;

  &--error {
    background: v.$defaultAlert;
  }

  @include m.tablet {
    padding: 30px 40px;
  }

  @include m.smallDesktop {
    padding: 30px 30px;
  }

  &__message {
    color: v.$defaultWhite;
    @include tm.typeBody--md;
  }
}