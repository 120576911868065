@use '../../GlobalScss/mixins/_btn-mixins' as bm;
@use '../../GlobalScss/variables.scss' as v;
@use '../../GlobalScss/mixins/type-mixins' as t;
@use '../../GlobalScss/mixins/mixins.scss' as m;

.residenceStatusWidget {
  display: inline-block;

  @include m.smallDesktop {
    display: block;
  }

  &--changeStatusIsOpen {
    display: block;
  }

  &__statusContainer {
    display: flex;
  }

  &__status {
    display: flex;
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;

    &--active {
      display: flex;
      flex: 1;
      width: auto;
      height: auto;
      transition:
        height 0.3s ease,
        opacity 0.3s ease;
      opacity: 1;
      pointer-events: all;
    }
  }

  &__statusPhrase {
    flex: 1;
    margin-right: 10px;
  }

  &__reserveBtn {
    @include bm.btn--small(
      var(--secondary),
      var(--secondary--darkened)
    );
  }

  &__changeStatus {
    display: flex;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;

    &--active {
      width: 100%;
      height: auto;
      flex: 1;
      opacity: 1;
      pointer-events: all;
      transition:
        height 0.3s ease,
        opacity 0.3s ease;
    }
  }

  &__dropdown {
    display:flex;
    position: relative;
    margin-right: 10px;
    width: 170px;

    @include m.smallDesktop {
      width: 165px;
    }

    @media screen and (min-width: 1035px) {
      width: 170px;
    }
  }

  &__dropdownTrigger {
    flex: 1;
    position: relative;
    padding: 0 35px 0 15px;
    @include t.typeDisplay;
    font-size: 16px;
    border-radius: 5px;
    color: v.$defaultCopy;
    background: v.$defaultWhite;
    border: 2px solid v.$defaultCopy;
    color: v.$defaultCopy;
    border-radius: 5px;
    text-align: left;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      right: 10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    &:before {
      top: calc(50% + 2px);
      border-top: 8px solid v.$defaultCopy;
    }

    &:after {
      bottom: calc(50% + 2px);
      border-bottom: 8px solid v.$defaultCopy;
    }
  }

  &__dropdownOptions {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 5px;
    border: 2px solid v.$defaultCopy;
    z-index: 1;
    width: 100%;

  }

  &__dropdownOption {
    display: block;
    padding: 8px 15px;
    @include t.typeDisplay;
    font-size: 16px;
    color: v.$defaultCopy;
    flex: 1;
    background: v.$defaultWhite;
    border-top: 1px solid v.$defaultCopy;
    text-align: left;

    &:first-of-type:not([class*="active"]) {
      border:none;
      border-radius: 5px 5px 0 0;
    }

    &:last-of-type:not([class*="active"]) {
      border-radius: 0 0 5px 5px;
    }

    &--active {
      background: v.$defaultCopy;
      color: v.$defaultWhite;
      border-radius: 0;
    }
  }

  &__save {
    @include bm.btn--small(
      var(--secondary),
      var(--secondary--darkened)
    );
    margin-right: 10px;
  }

  &__cancel {
    @include bm.minorBtn--small (v.$defaultCopy);
  }
}